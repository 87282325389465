<!-- components/AppProfileModal.vue -->
<script lang="ts" setup>
const { $api } = useNuxtApp();

const props = defineProps<{
  visible: boolean;
}>();

const emit = defineEmits<{
  "update:visible": [payload: boolean];
  success: [];
}>();

const isSubmitting = ref(false);
const message = ref("");
const error = ref("");
const errors = ref([]);
const referralCode = ref("");
const form = ref({
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  //   profile_image: '',
  //   gender: '',
  //   language: '',
  //   source: '',
  //device_token: fcm_token
});

const onClose = () => {
  emit("update:visible", false);
};

const verifyReferral = async (code: string) => {
  try {
    const res = await $api("/referrals/code/verify", {
      method: "POST",
      body: { referral_code: code },
    });
    return res?.isValid || false;
  } catch (error) {
    // Show appropriate error message
    error.value = error?.response?.data?.message || "Invalid referral code.";
    return false;
  }
};

const onSubmit = async () => {
  try {
    isSubmitting.value = true;

    // Only validate if referral code is provided
    if (referralCode.value) {
      const isValidReferral = await verifyReferral(referralCode.value);
      if (!isValidReferral) {
        return;
      }
    }
    const res = await $api("/profile", {
      method: "PUT",
      body: {
        ...form.value,
      },
    });

    if (res.profile_updated) {
      message.value = res.message;
      onClose();

      emit("success");
    } else {
      error.value = res.message;
    }
  } catch (e) {
    console.log(e);
    error.value = e.data.message;
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<template>
  <BModal
    :model-value="visible"
    @update:model-value="(v) => emit('update:visible', v)"
    size="lg"
    hide-header
    hide-footer
    body-class="p-0"
    content-class="rounded-5 overflow-hidden"
  >
    <template #default>
      <div class="row g-0">
        <div class="col-lg-7">
          <div
            class="d-flex align-items-center flex-column justify-content-center p-5"
          >
            <h1 class="text-center">   {{ $t("login_modal_title") }}</h1>
            <p class="mb-4 text-center">
              {{ $t("login_modal_subtitle") }}
            </p>

            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-12 col-md-6">
                  <AppInput
                    v-model="form.first_name"
                    autofocus
                    :placeholder="$t('first_name')"
                    type="text"
                    required
                    name="first_name"
                    :label="$t('first_name')"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <AppInput
                    v-model="form.last_name"
                    :placeholder="$t('last_name')"
                    type="text"
                    required
                    name="last_name"
                    :label="$t('last_name')"
                  />
                </div>
                <div class="col-12">
                  <AppInput
                    v-model="form.email"
                    :placeholder="$t('email')"
                    type="email"
                    required
                    name="email"
                    :label="$t('email')"
                  />
                </div>
                <div class="col-12">
                  <AppPhoneInput
                    v-model="form.phone"
                    :placeholder="$t('mobile_number')"
                    :disabled="true"
                    class="mb-2"
                  >
                    <template #label>
                      {{ $t('mobile_number') }}
                      <span class="text-danger"> ({{ $t('not_changeable') }})</span>
                    </template>
                  </AppPhoneInput>
                </div>
                <label
                  class="fs-14 fw-700 textPrimary mb-2 text-end d-block mt-3"
                >
                  {{ $t('referral') }}
                </label>
                <input
                  v-model="referralCode"
                  type="text"
                  class="form-control"
                  :placeholder="$t('enter_referral_code')"
                />

                <p v-if="errors['referralCode']" class="m-0 text-danger">
                  {{ errors["referralCode"] }}
                </p>

                <div class="col-12 mt-3">
                  <BButton
                    variant="primary"
                    block
                    class="w-100"
                    size="lg"
                    type="submit"
                    :loading="isSubmitting"
                    loading-text=""
                  >
                    {{ $t('create_an_account') }}
                  </BButton>

                  <p v-if="error" class="mt-3 txt-danger">{{ error }}</p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="d-none d-lg-block col-lg-5">
          <img src="assets/images/modal-car.png" class="w-100" loading="lazy" alt="modal car" />
        </div>
      </div>
    </template>
  </BModal>
</template>

<style lang="css" scoped></style>
